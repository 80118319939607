import React, { useState, useMemo } from 'react';
import axios from 'axios';
import Switch from 'components/Switch';
import AccountFormButton from './AccountFormButton';
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import { ISnowflakeAccount } from 'views/SnowflakeAccountsView';
import DeleteConfirmationDialog, { ObjectType } from './DeleteConfirmationDialog';
import { classNames } from 'utils/styleUtils';
import { useAppDispatch } from 'app/hooks';
import { fetchAccounts } from 'features/snowflake-accounts/reducers/snowflakeAccountsSlice';

const tooltipStyles = {
  ...defaultStyles,
  backgroundColor: '#4b5563',
  color: 'white',
  fontSize: 12,
  zIndex: 100,
  maxWidth: 150,
};

interface SnowflakeAccountCardProps {
  data: ISnowflakeAccount;
  setShowError: (showError: boolean) => void;
  setErrorMessage: (errorMessage: string) => void;
}

const SnowflakeAccountCard = (props: SnowflakeAccountCardProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [accountId] = useState<number>(props.data.id);
  const [account, setAccount] = useState<string>(props.data.account);
  const [username, setUsername] = useState<string>(props.data.username);
  const [warehouse, setWarehouse] = useState<string>(props.data.warehouse);
  const [role, setRole] = useState<string>(props.data.role);
  const [password, setPassword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [isMfa, setIsMfa] = useState<boolean>(props.data.is_mfa);

  const [privateKey, setPrivateKey] = useState<string>('');
  const [passphrase, setPassphrase] = useState<string>('');

  const isEditable = useMemo(() => props.data.is_editable, [props.data.is_editable]);

  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState<boolean>(false);

  const { tooltipOpen, tooltipLeft, tooltipTop } = useTooltip();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });
  console.log(containerRef);

  const handleCredetialsSubmit = (e: React.FormEvent): void => {
    e.preventDefault();
    setLoading(true);
    setFailed(false);
    let data = {};
    if (props.data.type === 'password') {
      data = {
        type: 'password',
        account,
        username,
        password,
        warehouse,
        role,
      };
    } else if (props.data.type === 'pkey') {
      data = {
        type: 'pkey',
        account,
        username,
        private_key: privateKey,
        private_key_pwd: passphrase !== '' ? passphrase : null,
        warehouse,
        role,
      };
    } else if (props.data.type === 'oauth') {
      data = {
        type: 'oauth',
        warehouse,
      };
    }
    axios
      .put(`/api/v1/snowflake/account/${props.data.id}`, data)
      .then((response) => {
        console.log(response);
      })
      .catch((err) => {
        console.log(err);
        setFailed(true);
        props.setErrorMessage(err.response.data);
        props.setShowError(true);
      })
      .finally(() => {
        setLoading(false);
        setEditMode(false);
      });
  };

  const changeIsMfa = (): void => {
    if (isMfa) {
      setIsMfa(false);
      axios
        .delete(`/api/v1/snowflake/account/${props.data.id}/mfa`)
        .then(() => {
          dispatch(fetchAccounts()).catch((err) => err);
        })
        .catch((err) => {
          console.log(err);
          setFailed(true);
        });
    } else {
      setIsMfa(true);
      axios
        .post(`/api/v1/snowflake/account/${props.data.id}/mfa`)
        .then(() => {
          dispatch(fetchAccounts()).catch((err) => err);
        })
        .catch((err) => {
          console.log(err);
          setFailed(true);
        });
    }
  };

  return (
    <div
      className={classNames(
        isEditable ? 'bg-white border-[2px] border-white' : 'bg-gray-50 border-[2px]',
        'p-4 px-8 rounded-md shadow-sm',
      )}
    >
      <div className="h-full max-w-md flex flex-col mx-[auto]">
        <div className="flex items-start mb-2">
          <div className="hidden items-center rounded-lg bg-gray-100 p-0.5 sm:flex">
            <img src="/static/snowflake-icon.png" className="h-5 w-5" aria-hidden="true" />
          </div>
          <div className={classNames('', 'text-lg text-gray-800 font-medium')}>{props.data.account}</div>
        </div>
        <div className="my-1 text-gray-700">Snowflake credentials</div>

        <form className="w-full flex-grow mt-1 flex flex-col">
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="-space-y-px rounded-md shadow-sm">
            <div className="flex max-w-lg shadow-sm">
              <span className="w-[8.5rem] inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-2 text-gray-500 sm:text-sm">
                Type
              </span>
              <input
                name="type"
                type="type"
                autoComplete="type"
                value={props.data.type}
                required
                className="relative block w-full appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                disabled
              />
            </div>
            <div className="flex max-w-lg shadow-sm">
              <span className="w-[8.5rem] inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-2 text-gray-500 sm:text-sm">
                Account
              </span>
              <input
                name="account"
                type="account"
                autoComplete="account"
                value={account}
                onChange={(e) => setAccount(e.target.value)}
                required
                className="relative block w-full appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                disabled
              />
            </div>
            <div className="flex max-w-lg rounded-md shadow-sm">
              <span className="w-[8.5rem] inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-2 text-gray-500 sm:text-sm">
                Username
              </span>
              <input
                name="username"
                type="username"
                autoComplete="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
                className="relative block w-full appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                disabled={!editMode || props.data.type === 'oauth'}
              />
            </div>
            <div className="flex max-w-lg rounded-md shadow-sm">
              <span className="w-[8.5rem] inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-2 text-gray-500 sm:text-sm">
                Warehouse
              </span>
              <input
                name="warehouse"
                type="warehouse"
                autoComplete="warehouse"
                value={warehouse}
                onChange={(e) => setWarehouse(e.target.value)}
                required
                className="relative block w-full appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                disabled={!editMode}
              />
            </div>
            <div className="flex max-w-lg rounded-md shadow-sm">
              <span className="w-[8.5rem] inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-2 text-gray-500 sm:text-sm">
                Role
              </span>
              <input
                name="role"
                value={role}
                onChange={(e) => setRole(e.target.value)}
                className="relative block w-full appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                disabled={!editMode || props.data.type === 'oauth'}
              />
            </div>
            {editMode && props.data.type === 'password' && (
              <div className="flex max-w-lg rounded-md shadow-sm">
                <span className="w-[8.5rem] inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-2 text-gray-500 sm:text-sm">
                  Password
                </span>
                <input
                  name="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className="relative block w-full appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                />
              </div>
            )}
            {editMode && props.data.type === 'pkey' && (
              <div className="flex max-w-lg rounded-md shadow-sm">
                <span className="w-[8.5rem] inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-2 text-gray-500 sm:text-sm">
                  Private Key
                </span>
                <textarea
                  rows={1}
                  name="privatekey"
                  id="privatekey-field"
                  value={privateKey}
                  onChange={(e) => setPrivateKey(e.target.value)}
                  placeholder="Paste your private key here"
                  required
                  className={classNames(
                    privateKey === '' ? '' : 'blurred-text',
                    'relative block w-full appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm',
                  )}
                />
              </div>
            )}
            {editMode && props.data.type === 'pkey' && (
              <div className="flex max-w-lg rounded-md shadow-sm">
                <span className="w-[8.5rem] inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-2 text-gray-500 sm:text-sm">
                  Passphrase
                </span>
                <input
                  type="password"
                  name="passphrase"
                  id="passphrase-field"
                  value={passphrase}
                  onChange={(e) => setPassphrase(e.target.value)}
                  placeholder="Passphrase for private key"
                  className="relative block w-full appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                />
              </div>
            )}
          </div>
          {!editMode && props.data.type === 'password' && (
            <div className="mt-4 flex items-center justify-around">
              {isEditable && (
                <Switch checked={isMfa} onChange={changeIsMfa} label="MFA caching" disabled={!isEditable} />
              )}
            </div>
          )}
          <div className="my-1 h-8 flex items-center justify-center">
            {loading && !failed && (
              <div
                className="animate-spin inline-block w-6 h-6 border-[2px] border-current border-t-transparent text-cyan-800 rounded-full"
                role="status"
                aria-label="loading"
              >
                <span className="sr-only">Loading...</span>
              </div>
            )}
            {failed && (
              <div className="text-sm w-full rounded bg-red-50 border-2 border-red-500 flex items-center justify-center">
                <a className="font-medium text-red-600 hover:text-red-500">Failed to update credentials</a>
              </div>
            )}
          </div>
          {!editMode && (
            <div className="w-full mt-[auto] px-4 flex space-x-4">
              <AccountFormButton label="Edit" onClick={() => setEditMode(true)} disabled={!isEditable} />
              <AccountFormButton
                label="Delete"
                onClick={(e) => {
                  e.preventDefault();
                  setOpenDeleteConfirmation(true);
                }}
                disabled={!isEditable}
              />
            </div>
          )}
          {editMode && (
            <div className="w-full mt-[auto] px-4 flex space-x-4">
              <AccountFormButton label="Cancel" onClick={() => setEditMode(false)} />
              <AccountFormButton label="Save" onClick={handleCredetialsSubmit} type="submit" />
            </div>
          )}
        </form>
      </div>
      {tooltipOpen && (
        <TooltipInPortal top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
          <div className="text-sm">This snowflake account is not persisted and will be removed on logout</div>
        </TooltipInPortal>
      )}
      <DeleteConfirmationDialog
        open={openDeleteConfirmation}
        setOpen={setOpenDeleteConfirmation}
        accountId={accountId}
        type={ObjectType.ACCOUNT}
      />
    </div>
  );
};

export default SnowflakeAccountCard;
