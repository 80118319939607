import React, { Fragment, useState } from 'react';
import { IOauthIntegration } from 'views/OauthIntegrationsView';
import { Dialog, Transition } from '@headlessui/react';
import FormField from 'features/snowflake-accounts/components/FormField';
import { useNavigate, createSearchParams } from 'react-router-dom';

interface SnowflakeOauthAccountDialogProps {
  integration: IOauthIntegration;
  dialogOpen: boolean;
  setDialogOpen: (x: boolean) => void;
}

export default function SnowflakeOauthAccountDialog(props: SnowflakeOauthAccountDialogProps): JSX.Element {
  const navigate = useNavigate();

  const [warehouse, setWarehouse] = useState<string>(props.integration.warehouse);
  const [role, setRole] = useState<string>(props.integration.role);
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("Couldn't connect to Snowflake");

  const formFields = [
    {
      name: 'Warehouse',
      value: warehouse,
      onChange: (e: any) => setWarehouse(e.target.value),
      onPaste: (e: any) => setWarehouse(e.target.value),
      placeholder: props.integration.warehouse,
      required: false,
    },
    {
      name: 'Role',
      value: role,
      onChange: (e: any) => setRole(e.target.value),
      onPaste: (e: any) => setRole(e.target.value),
      placeholder: props.integration.role,
      required: false,
    },
  ];

  const handleSubmit = (e: React.FormEvent): void => {
    navigate({ pathname: `${props.integration.id}/auth`, search: createSearchParams({ warehouse, role }).toString() });
  };
  const onClose = (): void => {
    props.setDialogOpen(false);
    setWarehouse('');
    setRole('');
    setLoading(false);
    setSuccess(false);
    setFailed(false);
    setErrorMessage('');
  };
  return (
    <Transition.Root show={props.dialogOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => onClose()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full justify-center text-center items-start sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="mt-[8rem] relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 text-left shadow-xl transition-all md:max-w-md">
                <div className="bg-white rounded-md">
                  <div className="flex min-h-full items-center justify-center py-2 px-4 sm:px-6 lg:px-8">
                    <div className="w-full">
                      <div className="text-center text-lg font-bold tracking-tight text-gray-900">
                        Snowflake credentials
                      </div>
                      {loading && !failed && (
                        <div className="mt-1 min-h-6 flex items-center justify-center">
                          <div
                            className="animate-spin inline-block w-6 h-6 border-[2px] border-current border-t-transparent text-cyan-800 rounded-full"
                            role="status"
                            aria-label="loading"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      )}
                      {failed && (
                        <div className="mt-1 min-h-6 flex items-center justify-center">
                          <div className="text-sm w-full rounded bg-red-50 border-2 border-red-500 flex items-center justify-center">
                            <a className="w-full text-center font-medium text-red-600 hover:text-red-500 break-words p-1">
                              {errorMessage}
                            </a>
                          </div>
                        </div>
                      )}
                      {success && (
                        <div className="mt-1 min-h-6 flex items-center justify-center">
                          <div className="text-sm w-full rounded bg-green-50 border-2 border-green-500 flex items-center justify-center">
                            <a className="font-medium text-green-600 hover:text-green-500">Account added</a>
                          </div>
                        </div>
                      )}
                      <form className="space-y-4 flex flex-col items-center" onSubmit={handleSubmit}>
                        <div className="rounded-md shadow-sm w-fit">
                          {formFields.map((field) => (
                            <FormField
                              key={field.name}
                              name={field.name}
                              value={field.value}
                              onChange={field.onChange}
                              onPaste={field.onPaste}
                              placeholder={field.placeholder}
                              required={field.required}
                              type="text"
                            />
                          ))}
                        </div>

                        <div className="w-full mt-[auto] px-4 flex space-x-4">
                          <button
                            onClick={() => onClose()}
                            className="group relative flex w-full justify-center rounded-md border border-transparent bg-dataops-secondary-blue hover:bg-hover-secondary-blue py-2 px-4 text-sm font-medium text-white focus:outline-none"
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="group relative flex w-full justify-center rounded-md border border-transparent bg-dataops-secondary-blue hover:bg-hover-secondary-blue py-2 px-4 text-sm font-medium text-white focus:outline-none"
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
