import React from 'react';

interface FormFieldProps {
  name: string;
  value: string;
  onChange: (e: any) => void;
  onPaste: (e: any) => void;
  placeholder: string;
  required: boolean;
  type: string | undefined;
}

export default function FormField(props: FormFieldProps): JSX.Element {
  return (
    <div className="flex rounded-md shadow-sm">
      <span className="w-[8rem] inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-2 text-gray-500 sm:text-sm">
        {props.name}
      </span>
      <input
        value={props.value}
        onChange={props.onChange}
        onPaste={props.onPaste}
        placeholder={props.placeholder}
        required={props.required}
        type={props.type ?? 'text'}
        className="relative w-[16rem] block appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
      />
    </div>
  );
}
