import React from 'react';
import { NavLink } from 'react-router-dom';
import { classNames } from 'utils/styleUtils';

const tabs = [
  { name: 'Snowflake accounts', href: '/snowflake-accounts' },
  { name: 'Oauth integrations', href: '/snowflake-accounts/integrations' },
];

interface SnowflakeAccountBarProps {
  setAccountDialogOpen: any;
  objectText: string;
  buttonDisabled?: boolean;
}

const SnowflakeAccountsBar = (props: SnowflakeAccountBarProps): JSX.Element => {
  return (
    <div className="fixed left-16 right-0 z-20 pl-8 pr-8 bg-white">
      <div className="border-b border-gray-200 flex items-center">
        <nav className="-mb-px flex space-x-8 flex-grow" aria-label="Tabs">
          {tabs.map((tab) => (
            <NavLink
              key={tab.name}
              to={tab.href}
              end
              className={({ isActive }) =>
                classNames(
                  isActive
                    ? 'border-dataops-secondary-blue text-dataops-secondary-blue'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                  'whitespace-nowrap py-4 px-1 border-b-2 font-[600] text-sm',
                )
              }
            >
              {tab.name}
            </NavLink>
          ))}
        </nav>
        {props.buttonDisabled ?? (
          <button
            type="button"
            className="inline-flex items-center rounded-md border border-transparent bg-dataops-secondary-blue hover:bg-hover-secondary-blue px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none"
            onClick={() => props.setAccountDialogOpen(true)}
          >
            Add {props.objectText}
          </button>
        )}
      </div>
    </div>
  );
};

export default SnowflakeAccountsBar;
