import React, { useState, Fragment } from 'react';
import axios from 'axios';
import { Dialog, Transition, Switch } from '@headlessui/react';
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import { classNames } from 'utils/styleUtils';
import FormField from 'features/snowflake-accounts/components/FormField';
const tooltipStyles = {
  ...defaultStyles,
  backgroundColor: '#4b5563',
  color: 'white',
  fontSize: 12,
  zIndex: 30,
  maxWidth: '200px',
};

interface TooltipData {
  description: string;
}

interface SnowflakeAccountBarProps {
  accountDialogOpen: boolean;
  setAccountDialogOpen: any;
}

// Exported for unit tests
export function parseAccountFromURL(url: string): string {
  const start = 'https://';
  const end = '.snowflakecomputing.com';
  const endWithSlash = '.snowflakecomputing.com/';
  const splitParts = url.split('.');
  if (url.startsWith(start) && url.endsWith(end)) {
    const account = url.substring(start.length, url.length - end.length);
    return account;
  } else if (url.startsWith(start) && url.endsWith(endWithSlash)) {
    const account = url.substring(start.length, url.length - endWithSlash.length);
    return account;
  } else if (splitParts.length === 2) {
    const account = splitParts.join('-');
    return account;
  }
  return url;
}

export default function SnowflakeCredentialsForm(props: SnowflakeAccountBarProps): JSX.Element {
  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } = useTooltip<TooltipData>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });

  const [account, setAccount] = useState<string>('');
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [warehouse, setWarehouse] = useState<string>('');
  const [role, setRole] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("Couldn't connect to Snowflake");
  const [trimAccount, setTrimAccount] = useState<boolean>(true);
  const [snowflakeAuthType, setSnowflakeAuthType] = useState<string>('password');

  const [privateKey, setPrivateKey] = useState<string>('');
  const [passphrase, setPassphrase] = useState<string>('');

  const tabs = ['Username-Password', 'Key-Pair'];
  const formFields = [
    {
      name: 'Account',
      value: account,
      onChange: (e: any) => setAccount(e.target.value),
      onPaste: (e: any) => {
        e.preventDefault();
        const pastedText = e.clipboardData.getData('text/plain');
        const parsedAccount = trimAccount ? parseAccountFromURL(pastedText) : pastedText;
        setAccount(parsedAccount);
      },
      placeholder: 'zdxupxd-training',
      required: true,
      type: 'text',
    },
    {
      name: 'Username',
      value: username,
      onChange: (e: any) => setUsername(e.target.value),
      onPaste: (e: any) => setUsername(e.target.value),
      placeholder: 'dataops_obs',
      required: true,
      type: 'text',
    },
    {
      name: 'Password',
      value: password,
      onChange: (e: any) => setPassword(e.target.value),
      onPaste: (e: any) => setPassword(e.target.value),
      placeholder: 'Your password',
      required: true,
      type: 'password',
    },
    {
      name: 'Warehouse',
      value: warehouse,
      onChange: (e: any) => setWarehouse(e.target.value),
      onPaste: (e: any) => setWarehouse(e.target.value),
      placeholder: 'DATAOPS_OBS_WH',
      required: true,
      type: 'text',
    },
    {
      name: 'Role',
      value: role,
      onChange: (e: any) => setRole(e.target.value),
      onPaste: (e: any) => setRole(e.target.value),
      placeholder: 'DATAOPS_OBS_VIEWER',
      required: false,
      type: 'text',
    },
  ];

  const handleSubmit = (e: React.FormEvent): void => {
    e.preventDefault();
    setLoading(true);
    setFailed(false);
    let data = {};
    if (snowflakeAuthType === 'password') {
      data = {
        type: 'password',
        account,
        username,
        password,
        warehouse,
        role,
      };
    } else if (snowflakeAuthType === 'key-pair') {
      data = {
        type: 'pkey',
        account,
        username,
        private_key: privateKey,
        private_key_pwd: passphrase !== '' ? passphrase : null,
        warehouse,
        role,
      };
    }
    axios
      .post('/api/v1/snowflake/account', data)
      .then((response) => {
        setLoading(false);
        setSuccess(true);
        setTimeout(() => {
          props.setAccountDialogOpen(false);
          setAccount('');
          setUsername('');
          setPassword('');
          setWarehouse('');
          setRole('');
          setLoading(false);
          setSuccess(false);
          setFailed(false);
        }, 1500);
      })
      .catch((err) => {
        console.log('axios error: ', err);
        setLoading(false);
        setFailed(true);
        setErrorMessage(err.response.data);
      });
  };

  const onClose = (): void => {
    props.setAccountDialogOpen(false);
    setAccount('');
    setUsername('');
    setPassword('');
    setWarehouse('');
    setRole('');
    setLoading(false);
    setSuccess(false);
    setFailed(false);
    setErrorMessage('');
  };

  return (
    <Transition.Root show={props.accountDialogOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => onClose()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full justify-center text-center items-start sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="mt-[8rem] relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 text-left shadow-xl transition-all md:max-w-md">
                <div className="bg-white rounded-md">
                  <div className="flex min-h-full items-center justify-center py-2 px-4 sm:px-6 lg:px-8">
                    <div className="w-full">
                      <div className="text-center text-lg font-bold tracking-tight text-gray-900">
                        Snowflake credentials
                      </div>
                      {loading && !failed && (
                        <div className="mt-1 min-h-6 flex items-center justify-center">
                          <div
                            className="animate-spin inline-block w-6 h-6 border-[2px] border-current border-t-transparent text-cyan-800 rounded-full"
                            role="status"
                            aria-label="loading"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      )}
                      {failed && (
                        <div className="mt-1 min-h-6 flex items-center justify-center">
                          <div className="text-sm w-full rounded bg-red-50 border-2 border-red-500 flex items-center justify-center">
                            <a className="w-full text-center font-medium text-red-600 hover:text-red-500 break-words p-1">
                              {errorMessage}
                            </a>
                          </div>
                        </div>
                      )}
                      {success && (
                        <div className="mt-1 min-h-6 flex items-center justify-center">
                          <div className="text-sm w-full rounded bg-green-50 border-2 border-green-500 flex items-center justify-center">
                            <a className="font-medium text-green-600 hover:text-green-500">Account added</a>
                          </div>
                        </div>
                      )}
                      <div className="w-[22rem]">
                        <div className="border-b border-gray-200 mb-6">
                          <nav aria-label="Tabs" className="-mb-px flex justify-between">
                            {tabs.map((tab) => (
                              <div
                                key={tab}
                                onClick={() =>
                                  setSnowflakeAuthType(
                                    tab === 'Username-Password'
                                      ? 'password'
                                      : tab === 'Key-Pair'
                                      ? 'key-pair'
                                      : 'password',
                                  )
                                }
                                className={classNames(
                                  (tab === 'Username-Password' && snowflakeAuthType === 'password') ||
                                    (tab === 'Key-Pair' && snowflakeAuthType === 'key-pair') ||
                                    (tab === 'Use Existing' && snowflakeAuthType === 'persisted')
                                    ? 'border-dataops-secondary-blue text-dataops-secondary-blue'
                                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                  'flex-1 whitespace-nowrap border-b-2 px-1 py-2 text-center text-sm font-medium cursor-pointer',
                                )}
                              >
                                {tab}
                              </div>
                            ))}
                          </nav>
                        </div>
                      </div>
                      {snowflakeAuthType === 'password' && (
                        <form className="space-y-4 flex flex-col items-center" onSubmit={handleSubmit}>
                          <div className="rounded-md shadow-sm w-fit">
                            <Switch.Group as="div" className="flex items-center justify-center ml-[auto] mb-1">
                              <Switch
                                checked={trimAccount}
                                onChange={() => setTrimAccount(!trimAccount)}
                                className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none"
                              >
                                <span className="sr-only">Use setting</span>
                                <span
                                  aria-hidden="true"
                                  className="pointer-events-none absolute h-full w-full rounded-md bg-white"
                                />
                                <span
                                  aria-hidden="true"
                                  className={classNames(
                                    trimAccount ? 'bg-dataops-primary-blue' : 'bg-gray-200',
                                    'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out',
                                  )}
                                />
                                <span
                                  aria-hidden="true"
                                  className={classNames(
                                    trimAccount ? 'translate-x-5' : 'translate-x-0',
                                    'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out',
                                  )}
                                />
                              </Switch>
                              <Switch.Label
                                as="span"
                                className="ml-1 cursor-default"
                                ref={containerRef}
                                onMouseLeave={() => {
                                  hideTooltip();
                                }}
                                onMouseMove={(event: any) => {
                                  const eventSvgCoords = localPoint(event);
                                  showTooltip({
                                    tooltipData: {
                                      description:
                                        'If enabled, account URL that is pasted into account field would be automatically re-formatted to the expected format',
                                    },
                                    tooltipTop: eventSvgCoords?.y,
                                    tooltipLeft: eventSvgCoords?.x,
                                  });
                                }}
                              >
                                <span className="text-sm font-medium text-gray-700">Trim account</span>
                              </Switch.Label>
                            </Switch.Group>
                            {formFields.map((field) => (
                              <FormField
                                key={field.name}
                                name={field.name}
                                value={field.value}
                                onChange={field.onChange}
                                onPaste={field.onPaste}
                                placeholder={field.placeholder}
                                required={field.required}
                                type={field.type}
                              />
                            ))}
                          </div>

                          <div className="w-full mt-[auto] px-4 flex space-x-4">
                            <button
                              onClick={() => onClose()}
                              className="group relative flex w-full justify-center rounded-md border border-transparent bg-dataops-secondary-blue hover:bg-hover-secondary-blue py-2 px-4 text-sm font-medium text-white focus:outline-none"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="group relative flex w-full justify-center rounded-md border border-transparent bg-dataops-secondary-blue hover:bg-hover-secondary-blue py-2 px-4 text-sm font-medium text-white focus:outline-none"
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      )}
                      {snowflakeAuthType === 'key-pair' && (
                        <form className="space-y-4 flex flex-col items-center" onSubmit={handleSubmit}>
                          <div className="rounded-md shadow-sm w-fit">
                            <div className="relative">
                              <Switch.Group as="div" className="flex items-center justify-center ml-[auto] mb-1">
                                <Switch
                                  checked={trimAccount}
                                  onChange={() => setTrimAccount(!trimAccount)}
                                  className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none"
                                >
                                  <span className="sr-only">Use setting</span>
                                  <span
                                    aria-hidden="true"
                                    className="pointer-events-none absolute h-full w-full rounded-md bg-white"
                                  />
                                  <span
                                    aria-hidden="true"
                                    className={classNames(
                                      trimAccount ? 'bg-dataops-primary-blue' : 'bg-gray-200',
                                      'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out',
                                    )}
                                  />
                                  <span
                                    aria-hidden="true"
                                    className={classNames(
                                      trimAccount ? 'translate-x-5' : 'translate-x-0',
                                      'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out',
                                    )}
                                  />
                                </Switch>
                                <Switch.Label as="span" className="ml-1 cursor-default flex items-center">
                                  <span className="text-sm font-medium text-gray-700">Trim account</span>
                                  {/* <InformationTooltip tooltip="If turned on, the Snowflake account URL you paste in the Account field is automatically cleaned and formatted. Turn off the toggle if you are unhappy with the result." /> */}
                                </Switch.Label>
                              </Switch.Group>
                            </div>
                            <div className="flex rounded-md shadow-sm">
                              <span className="w-[8rem] inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-2 text-gray-500 sm:text-sm">
                                Account
                              </span>
                              <input
                                value={account}
                                name="account"
                                id="account-field"
                                onChange={(e) => setAccount(e.target.value)}
                                onPaste={(e) => {
                                  e.preventDefault();
                                  const pastedText = e.clipboardData.getData('text/plain');
                                  const parsedAccount = trimAccount ? parseAccountFromURL(pastedText) : pastedText;
                                  setAccount(parsedAccount);
                                }}
                                placeholder="zdxupxd-training"
                                required
                                className="relative w-[16rem] block appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                              />
                            </div>
                            <div className="flex rounded-md shadow-sm">
                              <span className="w-[8rem] inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-2 text-gray-500 sm:text-sm">
                                Username
                              </span>
                              <input
                                value={username}
                                name="username"
                                id="username-field"
                                onChange={(e) => setUsername(e.target.value)}
                                placeholder="dataops_obs"
                                required
                                className="relative w-[16rem] block appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                              />
                            </div>
                            <div className="flex rounded-md shadow-sm">
                              <span className="w-[8rem] inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-2 text-gray-500 sm:text-sm">
                                Private Key
                              </span>
                              <textarea
                                rows={1}
                                name="privatekey"
                                id="privatekey-field"
                                value={privateKey}
                                onChange={(e) => setPrivateKey(e.target.value)}
                                placeholder="Paste your private key here"
                                required
                                className={classNames(
                                  privateKey === '' ? '' : 'blurred-text',
                                  'relative w-[16rem] block appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm',
                                )}
                              />
                            </div>
                            <div className="flex rounded-md shadow-sm">
                              <span className="w-[8rem] inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-2 text-gray-500 sm:text-sm">
                                Passphrase
                              </span>
                              <input
                                type="password"
                                name="passphrase"
                                id="passphrase-field"
                                value={passphrase}
                                onChange={(e) => setPassphrase(e.target.value)}
                                placeholder="Passphrase for private key"
                                className="relative w-[16rem] block appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                              />
                            </div>
                            <div className="flex rounded-md shadow-sm">
                              <span className="w-[8rem] inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-2 text-gray-500 sm:text-sm">
                                Warehouse
                              </span>
                              <input
                                value={warehouse}
                                name="warehouse"
                                id="warehouse-field"
                                onChange={(e) => setWarehouse(e.target.value)}
                                placeholder="DATAOPS_OBS_WH"
                                required
                                className="relative w-[16rem] block appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                              />
                            </div>
                            <div className="flex rounded-md shadow-sm">
                              <span className="w-[8rem] inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-2 text-gray-500 sm:text-sm">
                                Role
                              </span>
                              <input
                                value={role}
                                name="role"
                                id="role-field"
                                onChange={(e) => setRole(e.target.value)}
                                placeholder="DATAOPS_OBS_VIEWER"
                                className="relative w-[16rem] block appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                              />
                            </div>
                          </div>
                          <div className="w-full mt-[auto] px-4 flex space-x-4">
                            <button
                              onClick={() => onClose()}
                              className="group relative flex w-full justify-center rounded-md border border-transparent bg-dataops-secondary-blue hover:bg-hover-secondary-blue py-2 px-4 text-sm font-medium text-white focus:outline-none"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="group relative flex w-full justify-center rounded-md border border-transparent bg-dataops-secondary-blue hover:bg-hover-secondary-blue py-2 px-4 text-sm font-medium text-white focus:outline-none"
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      )}
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
          {tooltipOpen && tooltipData !== undefined && (
            <TooltipInPortal top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
              <div className="text-sm">{tooltipData.description}</div>
            </TooltipInPortal>
          )}
        </div>
      </Dialog>
    </Transition.Root>
  );
}
