import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import userManagementReducer from 'features/user-management/reducers/userManagementSlice';
import snowflakeAccountsReducer from 'features/snowflake-accounts/reducers/snowflakeAccountsSlice';
import { adminApi } from './apis';

export const store = configureStore({
  reducer: {
    userManagement: userManagementReducer,
    snowflakeAccounts: snowflakeAccountsReducer,
    [adminApi.reducerPath]: adminApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(adminApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
