import React, { useEffect, useState } from 'react';
import SnowflakeAccountsBar from 'features/snowflake-accounts/navigation/SnowflakeAccountsBar';
import OauthIntegrationform from 'features/snowflake-accounts/components/OauthIntegrationForm';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  selectOauthIntegrations,
  fetchOauthIntegrations,
} from 'features/snowflake-accounts/reducers/snowflakeAccountsSlice';
import OauthIntegrationCard from 'features/snowflake-accounts/components/OauthIntegrationCard';

export interface ISnowflakeAccount {
  account: string;
  username: string;
  warehouse: string;
  role: string;
  id: number;
  is_editable: boolean;
  is_mfa: boolean;
  type: string;
}

export interface IOauthIntegration {
  account: string;
  client_id: string;
  client_secret: string;
  id: number;
  is_editable: boolean;
  token_url: string;
  warehouse: string;
  role: string;
  is_shared_with_group: boolean;
}

export default function OauthIntegrationsView(): JSX.Element {
  const dispatch = useAppDispatch();
  const oauthIntegrations = useAppSelector(selectOauthIntegrations);
  const [accountDialogOpen, setAccountDialogOpen] = useState(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    dispatch(fetchOauthIntegrations()).catch((err) => err);
  }, [accountDialogOpen]);

  return (
    <>
      <div className="h-full min-w-0 flex-1">
        <SnowflakeAccountsBar setAccountDialogOpen={setAccountDialogOpen} objectText="integration" />
        {showError && (
          <>
            <div className="fixed top-[7.2rem] left-16 right-0 z-10 rounded-md bg-red-50 p-4">
              <div className="flex w-full">
                <div className="ml-3 w-full">
                  <div className="flex">
                    <h3 className="flex-grow text-sm font-medium text-red-800">Update error</h3>
                    <button
                      type="button"
                      onClick={() => setShowError(false)}
                      className="h-5 w-5 mr-4 inline-flex items-center rounded-full text-red-800 hover:bg-red-100"
                    >
                      <XMarkIcon className="w-full" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="w-full text-sm text-red-800">{errorMessage}</div>
                </div>
              </div>
            </div>
            <div className="mt-[7.5rem]"></div>
          </>
        )}
        <div className="w-full h-full min-w-0 mt-[3.2rem] sm:p-6 lg:p-8 bg-dashboard-background">
          <div className="grid grid-cols-1 gap-10 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 wide:grid-cols-4 auto-rows-[26rem]">
            {oauthIntegrations.map((integration) => (
              <OauthIntegrationCard
                key={integration.account}
                data={integration}
                setShowError={setShowError}
                setErrorMessage={setErrorMessage}
              />
            ))}
          </div>
        </div>
      </div>
      <OauthIntegrationform accountDialogOpen={accountDialogOpen} setAccountDialogOpen={setAccountDialogOpen} />
    </>
  );
}
