import React from 'react';
import { NavLink } from 'react-router-dom';
import { UserIcon, SquaresPlusIcon, PresentationChartBarIcon, CircleStackIcon } from '@heroicons/react/24/outline';
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import { classNames } from 'utils/styleUtils';

interface TooltipData {
  label: string;
}

const tooltipStyles = {
  ...defaultStyles,
  backgroundColor: '#4b5563',
  color: 'white',
  fontSize: 12,
  zIndex: 100,
};

const Sidebar = (): JSX.Element => {
  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } = useTooltip<TooltipData>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });

  const sidebarNavigation = [
    {
      name: 'DataOps.live Platform',
      href: process.env.REACT_APP_ENV === 'prod' ? 'https://app.dataops.live' : 'https://app.qa.dataops.live',
      icon: SquaresPlusIcon,
      disabled: false,
    },
    {
      name: 'DataOps.live Observability',
      href:
        process.env.REACT_APP_ENV === 'prod'
          ? 'https://observability.dataops.live'
          : 'https://observability.qa.dataops.live',
      icon: PresentationChartBarIcon,
      disabled: false,
    },
    { name: 'User Management', href: '/user-management', icon: UserIcon, disabled: false },
    { name: 'Snowflake accounts management', href: '/snowflake-accounts', icon: CircleStackIcon, disabled: false },
  ];

  return (
    <nav
      aria-label="Sidebar"
      className="fixed z-30 h-full md:block flex-shrink-0 overflow-y-auto border-r-[1px] border-solid bg-white"
    >
      <div className="relative mt-4 flex w-16 flex-col space-y-4 p-2" ref={containerRef}>
        {sidebarNavigation.map((item) => (
          <div
            key={item.name}
            onMouseLeave={() => {
              hideTooltip();
            }}
            onMouseMove={(event) => {
              showTooltip({
                tooltipData: { label: item.name },
                tooltipLeft: event.clientX + 15,
                tooltipTop: event.clientY - 80,
              });
            }}
          >
            {item.disabled ? (
              <div className="text-gray-500 bg-gray-200 flex-shrink-0 inline-flex items-center justify-center h-10 w-10 rounded-lg">
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-5 w-5" aria-hidden="true" />
              </div>
            ) : (
              <NavLink
                to={item.href}
                className={({ isActive }) =>
                  classNames(
                    isActive ? 'bg-dataops-primary-blue text-white' : 'text-gray-500 bg-gray-200 hover:bg-gray-300',
                    'flex-shrink-0 inline-flex items-center justify-center h-10 w-10 rounded-lg',
                  )
                }
              >
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-5 w-5" aria-hidden="true" />
              </NavLink>
            )}
          </div>
        ))}
      </div>
      {tooltipOpen && tooltipData !== undefined && (
        <TooltipInPortal top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
          <div className="text-sm">{tooltipData.label}</div>
        </TooltipInPortal>
      )}
    </nav>
  );
};

export default Sidebar;
