import React, { useEffect, useState } from 'react';
import { AuthProvider } from 'react-oidc-context';
import { UserManager, WebStorageStateStore } from 'oidc-client-ts';
import { useParams, useSearchParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import {
  selectOauthIntegrations,
  fetchOauthIntegrations,
} from 'features/snowflake-accounts/reducers/snowflakeAccountsSlice';
import SnowflakeAccountLoginInnerView from '../features/snowflake-accounts/components/SnowflakeAccountLoginInnerView';
import LoadingView from './LoadingView';
import axios from 'axios';
import { ISnowflakeAccount } from './SnowflakeAccountsView';

export default function SnowflakeAccountLoginView(): JSX.Element {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const oauthIntegrations = useAppSelector(selectOauthIntegrations);

  const accountId = searchParams.get('account_id');
  const redirectUrl = searchParams.get('redirect_url');

  const [role, setRole] = useState<string | null>(searchParams.get('role'));
  const [warehouse, setWarehouse] = useState<string | null>(searchParams.get('warehouse'));
  const [integrationId, setIntegrationId] = useState<string | undefined>(
    id !== undefined ? id : searchParams.get('integrationId') ?? undefined,
  );

  useEffect(() => {
    if (accountId !== null) {
      axios
        .get<ISnowflakeAccount>(`/api/v1/snowflake/account/${accountId}`)
        .then((response) => {
          setRole(response.data.role);
          setWarehouse(response.data.warehouse);
          if (response.data.oauth_integration === undefined) {
            return;
          }
          setIntegrationId(response.data.oauth_integration.toString());
        })
        .catch((err) => err);
    }
  }, [accountId]);

  if (integrationId === undefined) {
    return <LoadingView />;
  }

  if (integrationId !== null && oauthIntegrations.length === 0) {
    dispatch(fetchOauthIntegrations()).catch((err) => err);
  }

  const oauthIntegration = oauthIntegrations.find((integration) => integration.id === +integrationId);
  if (oauthIntegration === undefined) {
    return <LoadingView />;
  }
  if (role == null || warehouse == null) {
    return <LoadingView />;
  }
  const params = [
    ['integrationId', integrationId],
    ['role', role],
    ['warehouse', warehouse],
  ];
  if (accountId !== null) {
    params.push(['account_id', accountId]);
  }
  if (redirectUrl !== null) {
    params.push(['redirect_url', redirectUrl]);
  }
  const redirectParams = new URLSearchParams(params);
  const userManager = new UserManager({
    authority: oauthIntegration.token_url.split('/oauth/token-request')[0],
    metadata: {
      authorization_endpoint: oauthIntegration.token_url.replace('token-request', 'authorize'),
    },
    client_id: oauthIntegration.client_id,
    client_secret: oauthIntegration.client_secret,
    redirect_uri: `${window.location.origin}/snowflake-accounts/auth?${redirectParams.toString()}`,
    post_logout_redirect_uri: window.location.origin,
    userStore: new WebStorageStateStore({ store: window.localStorage, prefix: 'snowflake' }),
    monitorSession: true,
    scope: `session:role:${role}`,
    disablePKCE: true,
  });
  return (
    <AuthProvider userManager={userManager}>
      <SnowflakeAccountLoginInnerView />
    </AuthProvider>
  );
}
