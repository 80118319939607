import React, { useState, Fragment } from 'react';
import axios from 'axios';
import { Dialog, Transition, Switch } from '@headlessui/react';
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import { classNames } from 'utils/styleUtils';
import { parseAccountFromURL } from './SnowflakeCredentialsForm';
import FormField from './FormField';

const tooltipStyles = {
  ...defaultStyles,
  backgroundColor: '#4b5563',
  color: 'white',
  fontSize: 12,
  zIndex: 30,
  maxWidth: '200px',
};

interface TooltipData {
  description: string;
}

interface SnowflakeAccountBarProps {
  accountDialogOpen: boolean;
  setAccountDialogOpen: any;
}

export default function OauthIntegrationForm(props: SnowflakeAccountBarProps): JSX.Element {
  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } = useTooltip<TooltipData>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });

  const [account, setAccount] = useState<string>('');
  const [warehouse, setWarehouse] = useState<string>('');
  const [role, setRole] = useState<string>('');
  const [clientId, setClientId] = useState<string>('');
  const [clientSecret, setClientSecret] = useState<string>('');
  const [tokenUrl, setTokenUrl] = useState<string>('');
  const [trimAccount, setTrimAccount] = useState<boolean>(true);

  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("Couldn't connect to Snowflake");

  const formFields = [
    {
      name: 'Account',
      value: account,
      onChange: (e: any) => setAccount(e.target.value),
      onPaste: (e: any) => {
        e.preventDefault();
        const pastedText = e.clipboardData.getData('text/plain');
        const parsedAccount = trimAccount ? parseAccountFromURL(pastedText) : pastedText;
        setAccount(parsedAccount);
      },
      placeholder: 'zdxupxd-training',
      required: true,
      type: 'text',
    },
    {
      name: 'Warehouse',
      value: warehouse,
      onChange: (e: any) => setWarehouse(e.target.value),
      onPaste: (e: any) => setWarehouse(e.target.value),
      placeholder: 'DATAOPS_OBS_WH',
      required: false,
      type: 'text',
    },
    {
      name: 'Role',
      value: role,
      onChange: (e: any) => setRole(e.target.value),
      onPaste: (e: any) => setRole(e.target.value),
      placeholder: 'DATAOPS_OBS_VIEWER',
      required: false,
      type: 'text',
    },
    {
      name: 'Token URL',
      value: tokenUrl,
      onChange: (e: any) => setTokenUrl(e.target.value),
      onPaste: (e: any) => setTokenUrl(e.target.value),
      placeholder: 'https://zdxupxd-training.snowflakecomputing.com/oauth/token-request',
      required: true,
      type: 'text',
    },
    {
      name: 'Client Id',
      value: clientId,
      onChange: (e: any) => setClientId(e.target.value),
      onPaste: (e: any) => setClientId(e.target.value),
      placeholder: 'CLIENT_ID',
      required: true,
      type: 'text',
    },
    {
      name: 'Client Secret',
      value: clientSecret,
      onChange: (e: any) => setClientSecret(e.target.value),
      onPaste: (e: any) => setClientSecret(e.target.value),
      placeholder: 'CLIENT_SECRET',
      required: true,
      type: 'password',
    },
  ];

  const handleSubmit = (e: React.FormEvent): void => {
    e.preventDefault();
    setLoading(true);
    setFailed(false);
    axios
      .post('/api/v1/snowflake/oauth-integration', {
        account,
        warehouse,
        role,
        client_id: clientId,
        client_secret: clientSecret,
        token_url: tokenUrl,
      })
      .then((response) => {
        setLoading(false);
        setSuccess(true);
        setTimeout(() => {
          props.setAccountDialogOpen(false);
          setAccount('');
          setRole('');
          setLoading(false);
          setSuccess(false);
          setFailed(false);
        }, 1500);
      })
      .catch((err) => {
        console.log('axios error: ', err);
        setLoading(false);
        setFailed(true);
        setErrorMessage(err.response.data);
      });
  };

  const onClose = (): void => {
    props.setAccountDialogOpen(false);
    setAccount('');
    setWarehouse('');
    setRole('');
    setLoading(false);
    setSuccess(false);
    setFailed(false);
    setErrorMessage('');
  };

  return (
    <Transition.Root show={props.accountDialogOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => onClose()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full justify-center text-center items-start sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="mt-[8rem] relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 text-left shadow-xl transition-all md:max-w-md">
                <div className="bg-white rounded-md">
                  <div className="flex min-h-full items-center justify-center py-2 px-4 sm:px-6 lg:px-8">
                    <div className="w-full">
                      <div className="text-center text-lg font-bold tracking-tight text-gray-900">
                        Oauth integrations information
                      </div>
                      {loading && !failed && (
                        <div className="mt-1 min-h-6 flex items-center justify-center">
                          <div
                            className="animate-spin inline-block w-6 h-6 border-[2px] border-current border-t-transparent text-cyan-800 rounded-full"
                            role="status"
                            aria-label="loading"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      )}
                      {failed && (
                        <div className="mt-1 min-h-6 flex items-center justify-center">
                          <div className="text-sm w-full rounded bg-red-50 border-2 border-red-500 flex items-center justify-center">
                            <a className="w-full text-center font-medium text-red-600 hover:text-red-500 break-words p-1">
                              {errorMessage}
                            </a>
                          </div>
                        </div>
                      )}
                      {success && (
                        <div className="mt-1 min-h-6 flex items-center justify-center">
                          <div className="text-sm w-full rounded bg-green-50 border-2 border-green-500 flex items-center justify-center">
                            <a className="font-medium text-green-600 hover:text-green-500">Account added</a>
                          </div>
                        </div>
                      )}
                      <form className="space-y-4" onSubmit={handleSubmit}>
                        <input type="hidden" name="remember" defaultValue="true" />
                        <div className="-space-y-px rounded-md shadow-sm">
                          <Switch.Group as="div" className="flex items-center justify-center ml-[auto] mb-1">
                            <Switch
                              checked={trimAccount}
                              onChange={() => setTrimAccount(!trimAccount)}
                              className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none"
                            >
                              <span className="sr-only">Use setting</span>
                              <span
                                aria-hidden="true"
                                className="pointer-events-none absolute h-full w-full rounded-md bg-white"
                              />
                              <span
                                aria-hidden="true"
                                className={classNames(
                                  trimAccount ? 'bg-dataops-primary-blue' : 'bg-gray-200',
                                  'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out',
                                )}
                              />
                              <span
                                aria-hidden="true"
                                className={classNames(
                                  trimAccount ? 'translate-x-5' : 'translate-x-0',
                                  'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out',
                                )}
                              />
                            </Switch>
                            <Switch.Label
                              as="span"
                              className="ml-1 cursor-default"
                              ref={containerRef}
                              onMouseLeave={() => {
                                hideTooltip();
                              }}
                              onMouseMove={(event: any) => {
                                const eventSvgCoords = localPoint(event);
                                showTooltip({
                                  tooltipData: {
                                    description:
                                      'If enabled, account URL that is pasted into account field would be automatically re-formatted to the expected format',
                                  },
                                  tooltipTop: eventSvgCoords?.y,
                                  tooltipLeft: eventSvgCoords?.x,
                                });
                              }}
                            >
                              <span className="text-sm font-medium text-gray-700">Trim account</span>
                            </Switch.Label>
                          </Switch.Group>
                          {formFields.map((field) => (
                            <FormField
                              key={field.name}
                              name={field.name}
                              value={field.value}
                              onChange={field.onChange}
                              onPaste={field.onPaste}
                              placeholder={field.placeholder}
                              required={field.required}
                              type={field.type}
                            />
                          ))}
                        </div>

                        <div className="w-full mt-[auto] px-4 flex space-x-4">
                          <button
                            onClick={() => onClose()}
                            className="group relative flex w-full justify-center rounded-md border border-transparent bg-dataops-secondary-blue hover:bg-hover-secondary-blue py-2 px-4 text-sm font-medium text-white focus:outline-none"
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="group relative flex w-full justify-center rounded-md border border-transparent bg-dataops-secondary-blue hover:bg-hover-secondary-blue py-2 px-4 text-sm font-medium text-white focus:outline-none"
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
          {tooltipOpen && tooltipData !== undefined && (
            <TooltipInPortal top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
              <div className="text-sm">{tooltipData.description}</div>
            </TooltipInPortal>
          )}
        </div>
      </Dialog>
    </Transition.Root>
  );
}
