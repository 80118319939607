import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../../../app/store';
import { ISnowflakeAccount } from 'views/SnowflakeAccountsView';
import { IOauthIntegration } from 'views/OauthIntegrationsView';
import { IRequestStatus } from 'utils/types';

export interface SnowflakeAccountsState {
  accounts: ISnowflakeAccount[];
  accountsStatus: IRequestStatus;
  oauthIntegrations: IOauthIntegration[];
  oauthIntegrationsStatus: IRequestStatus;
  showError: boolean;
  isInstatiatingOauth: boolean;
  errorMessage: string;
}

const initialState: SnowflakeAccountsState = {
  accounts: [],
  accountsStatus: 'idle',
  oauthIntegrations: [],
  oauthIntegrationsStatus: 'idle',
  showError: false,
  isInstatiatingOauth: false,
  errorMessage: '',
};

export const fetchAccounts = createAsyncThunk('accounts', async () => {
  const response = await axios.get('/api/v1/snowflake/account');
  return response.data;
});

export const fetchOauthIntegrations = createAsyncThunk('oauthIntegrations', async () => {
  const response = await axios.get('/api/v1/snowflake/oauth-integration');
  return response.data;
});

export const snowflakeAccountsSlice = createSlice({
  name: 'snowflakeAccounts',
  initialState,
  reducers: {
    setIsInstatiatingOauth: (state, isInstatiating: PayloadAction<boolean>) => {
      state.isInstatiatingOauth = isInstatiating.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAccounts.pending, (state) => {
        state.accountsStatus = 'loading';
      })
      .addCase(fetchAccounts.fulfilled, (state, action) => {
        state.accountsStatus = 'idle';
        const processed = Object.keys(action.payload).map((key) => action.payload[key]);
        state.accounts = processed;
      })
      .addCase(fetchAccounts.rejected, (state) => {
        state.accountsStatus = 'failed';
      })
      .addCase(fetchOauthIntegrations.pending, (state) => {
        state.oauthIntegrationsStatus = 'loading';
      })
      .addCase(fetchOauthIntegrations.fulfilled, (state, action) => {
        state.oauthIntegrationsStatus = 'idle';
        const processed = Object.keys(action.payload).map((key) => action.payload[key]);
        state.oauthIntegrations = processed;
      })
      .addCase(fetchOauthIntegrations.rejected, (state) => {
        state.oauthIntegrationsStatus = 'failed';
      });
  },
});

export const { setIsInstatiatingOauth } = snowflakeAccountsSlice.actions;

export const selectAccounts = (state: RootState): ISnowflakeAccount[] => state.snowflakeAccounts.accounts;
export const selectOauthIntegrations = (state: RootState): IOauthIntegration[] =>
  state.snowflakeAccounts.oauthIntegrations;
export const selectIsInstatiatingOauth = (state: RootState): boolean => state.snowflakeAccounts.isInstatiatingOauth;

export default snowflakeAccountsSlice.reducer;
