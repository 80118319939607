import React, { useState } from 'react';
import axios from 'axios';
import AccountFormButton from './AccountFormButton';
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import { IOauthIntegration } from 'views/OauthIntegrationsView';
import DeleteConfirmationDialog, { ObjectType } from './DeleteConfirmationDialog';
import { classNames } from 'utils/styleUtils';
import SnowflakeOauthAccountDialog from './SnowflakeOauthAccountDialog';
import Switch from 'components/Switch';
import { fetchOauthIntegrations } from '../reducers/snowflakeAccountsSlice';
import { useAppDispatch } from 'app/hooks';

const tooltipStyles = {
  ...defaultStyles,
  backgroundColor: '#4b5563',
  color: 'white',
  fontSize: 12,
  zIndex: 100,
  maxWidth: 150,
};

interface OauthIntegrationCardProps {
  data: IOauthIntegration;
  setShowError: (showError: boolean) => void;
  setErrorMessage: (errorMessage: string) => void;
}

const OauthIntegrationCard = (props: OauthIntegrationCardProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [account, setAccount] = useState<string>(props.data.account);
  const [warehouse, setWarehouse] = useState<string>(props.data.warehouse);
  const [role, setRole] = useState<string>(props.data.role);
  const [clientId, setClientId] = useState<string>(props.data.client_id);
  const [clientSecret, setClientSecret] = useState<string>(props.data.client_secret);
  const [tokenUrl, setTokenUrl] = useState<string>(props.data.token_url);
  const [loading, setLoading] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [isSharedWithGroup, setIsSharedWithGroup] = useState<boolean>(props.data.is_shared_with_group);
  const isEditable = props.data.is_editable;

  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState<boolean>(false);
  const [openAuthenticateDialog, setOpenAuthenticateDialog] = useState<boolean>(false);

  const { tooltipOpen, tooltipLeft, tooltipTop } = useTooltip();
  const { TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });

  const handleCredetialsSubmit = (e: React.FormEvent): void => {
    e.preventDefault();
    setLoading(true);
    setFailed(false);
    axios
      .put(`/api/v1/snowflake/oauth-integration/${props.data.id}`, {
        account,
        warehouse,
        role,
        client_id: clientId,
        client_secret: clientSecret,
        token_url: tokenUrl,
      })
      .then((response) => {
        console.log(response);
      })
      .catch((err) => {
        console.log(err);
        setFailed(true);
        props.setErrorMessage(err.response.data);
        props.setShowError(true);
      })
      .finally(() => {
        setLoading(false);
        setEditMode(false);
      });
  };

  const changeIsSharedWithGroup = (): void => {
    if (isSharedWithGroup) {
      setIsSharedWithGroup(false);
      axios
        .delete(`/api/v1/snowflake/oauth-integration/${props.data.id}/share`)
        .then(() => {
          dispatch(fetchOauthIntegrations()).catch((err) => err);
        })
        .catch((err) => {
          console.log(err);
          setFailed(true);
        });
    } else {
      setIsSharedWithGroup(true);
      axios
        .post(`/api/v1/snowflake/oauth-integration/${props.data.id}/share`)
        .then(() => {
          dispatch(fetchOauthIntegrations()).catch((err) => err);
        })
        .catch((err) => {
          console.log(err);
          setFailed(true);
        });
    }
  };

  return (
    <div
      className={classNames(
        isEditable ? 'bg-white border-[2px] border-white' : 'bg-gray-50 border-[2px]',
        'p-4 px-8 rounded-md shadow-sm',
      )}
    >
      <div className="h-full max-w-md flex flex-col mx-[auto]">
        <div className="flex items-start mb-2">
          <div className="hidden items-center rounded-lg bg-gray-100 p-0.5 sm:flex">
            <img src="/static/snowflake-icon.png" className="h-5 w-5" aria-hidden="true" />
          </div>
          <div className={classNames('', 'text-lg text-gray-800 font-medium')}>{props.data.account}</div>
        </div>
        <div className="my-1 text-gray-700">Oauth integration details</div>

        <form className="w-full flex-grow mt-1 flex flex-col">
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="-space-y-px rounded-md shadow-sm">
            <div className="flex max-w-lg shadow-sm">
              <span className="w-[8.5rem] inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-2 text-gray-500 sm:text-sm">
                Account
              </span>
              <input
                name="account"
                type="account"
                autoComplete="account"
                value={account}
                onChange={(e) => setAccount(e.target.value)}
                required
                className="relative block w-full appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                disabled
              />
            </div>

            <div className="flex max-w-lg rounded-md shadow-sm">
              <span className="w-[8.5rem] inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-2 text-gray-500 sm:text-sm">
                Warehouse
              </span>
              <input
                name="warehouse"
                type="warehouse"
                autoComplete="warehouse"
                value={warehouse}
                onChange={(e) => setWarehouse(e.target.value)}
                required
                className="relative block w-full appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                disabled={!editMode}
              />
            </div>
            <div className="flex max-w-lg rounded-md shadow-sm">
              <span className="w-[8.5rem] inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-2 text-gray-500 sm:text-sm">
                Role
              </span>
              <input
                name="role"
                value={role}
                onChange={(e) => setRole(e.target.value)}
                className="relative block w-full appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                disabled={!editMode}
              />
            </div>

            <div className="flex max-w-lg rounded-md shadow-sm">
              <span className="w-[8.5rem] inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-2 text-gray-500 sm:text-sm">
                Token URL
              </span>
              <input
                name="tokenUrl"
                value={tokenUrl}
                onChange={(e) => setTokenUrl(e.target.value)}
                className="relative block w-full appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                disabled={!editMode}
              />
            </div>
            <div className="flex max-w-lg rounded-md shadow-sm">
              <span className="w-[8.5rem] inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-2 text-gray-500 sm:text-sm">
                Client Id
              </span>
              <input
                name="clientId"
                type="clientId"
                autoComplete="clientId"
                value={clientId}
                onChange={(e) => setClientId(e.target.value)}
                required
                className="relative block w-full appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                disabled={!editMode}
              />
            </div>
            {editMode && (
              <div className="flex max-w-lg rounded-md shadow-sm">
                <span className="w-[8.5rem] inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-2 text-gray-500 sm:text-sm">
                  Client Secret
                </span>
                <input
                  name="clientSecret"
                  type="password"
                  autoComplete="clientSecret"
                  value={clientSecret}
                  onChange={(e) => setClientSecret(e.target.value)}
                  required
                  className="relative block w-full appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                  disabled={!editMode}
                />
              </div>
            )}
          </div>
          {!editMode && (
            <div className="mt-4 flex items-center justify-around">
              {isEditable && (
                <Switch
                  checked={isSharedWithGroup}
                  onChange={changeIsSharedWithGroup}
                  label="Shared with group"
                  disabled={!isEditable}
                />
              )}
            </div>
          )}
          <div className="my-1 h-8 flex items-center justify-center">
            {loading && !failed && (
              <div
                className="animate-spin inline-block w-6 h-6 border-[2px] border-current border-t-transparent text-cyan-800 rounded-full"
                role="status"
                aria-label="loading"
              >
                <span className="sr-only">Loading...</span>
              </div>
            )}
            {failed && (
              <div className="text-sm w-full rounded bg-red-50 border-2 border-red-500 flex items-center justify-center">
                <a className="font-medium text-red-600 hover:text-red-500">Failed to update credentials</a>
              </div>
            )}
          </div>
          {!editMode && (
            <div className="w-full mt-[auto] px-4 flex space-x-4">
              <AccountFormButton label="Edit" onClick={() => setEditMode(true)} disabled={!isEditable} />
              <AccountFormButton
                label="Delete"
                onClick={(e) => {
                  e.preventDefault();
                  setOpenDeleteConfirmation(true);
                }}
                disabled={!isEditable}
              />
              <AccountFormButton label="Authenticate" onClick={() => setOpenAuthenticateDialog(true)} />
            </div>
          )}
          {editMode && (
            <div className="w-full mt-[auto] px-4 flex space-x-4">
              <AccountFormButton label="Cancel" onClick={() => setEditMode(false)} />
              <AccountFormButton label="Save" onClick={handleCredetialsSubmit} type="submit" />
            </div>
          )}
        </form>
      </div>
      {tooltipOpen && (
        <TooltipInPortal top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
          <div className="text-sm">This snowflake account is not persisted and will be removed on logout</div>
        </TooltipInPortal>
      )}

      <DeleteConfirmationDialog
        open={openDeleteConfirmation}
        setOpen={setOpenDeleteConfirmation}
        accountId={props.data.id}
        type={ObjectType.INTEGRATION}
      />
      <SnowflakeOauthAccountDialog
        integration={props.data}
        dialogOpen={openAuthenticateDialog}
        setDialogOpen={setOpenAuthenticateDialog}
      />
    </div>
  );
};

export default OauthIntegrationCard;
